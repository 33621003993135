<template>
  <div>
    <div
      v-if="!loadingNow && appOptions.versionType !== 'simple'"
      class="row items-center q-pr-xs"
    >
      <img
        class="show-on-mobile q-mr-sm"
        :src="appOptions.securityLogo"
        style="max-width: 70px; max-height: 100%; object-fit: contain;"
      >

      <div class="col text-left q-px-xs">
        <h5 class="q-my-none text-weight-bold">
          {{ $t('Welcome!') }}
        </h5>

        <p class="q-mb-none text-caption">
          {{ $t('Create your account') }}
        </p>
      </div>
    </div>

    <div
      v-if="error && !loadingNow"
      class="text-red text-center text-subtitle1"
      style="margin-top: -20px;"
    >
      <div>
        <div v-for="(message, index) in error" :key="index">
            <b style="text-transform: capitalize">{{ $t(index)}}:</b> {{ $t(message.isEmpty) }}
        </div>
      </div>
    </div>

    <div
      v-if="loadingNow"
      class="q-ma-xl"
    >
      <q-spinner
        color="light-blue-9"
        size="3rem"
        class="q-mr-md"
      />
    </div>

    <form
      v-else
      class="q-py-lg q-px-sm full-width"
      @submit="checkForm"
    >
      <form-builder :schema="schema" />

      <div class="q-my-lg q-px-xs">
        <q-btn
          :color="this.simple ? 'green-9' : 'light-blue-9'"
          size="lg"
          class="full-width"
          type="submit"
          no-caps
          :disable="!!passwordError || repeatPassword !== password"
          :label="$t('Sign up')"
        />
      </div>

      <div class="text-left">
        {{ $t('By clicking Sign Up, you agree to our' ) }} <a
          :href="termsLink"
          style="text-decoration: none;"
          class="text-light-blue-9"
        >{{ $t('Terms') }}</a> {{ $t('and that you have read our') }}
        <a
          :href="cookiesLink"
          style="text-decoration: none;"
          class="text-light-blue-9"
        > {{ $t('Data Policy') }}</a>, {{ $t('including our') }} <a
          :href="cookiesLink"
          style="text-decoration: none;"
          class="text-light-blue-9"
        >{{ $t('Cookie Use') }}</a>.
      </div>

      <div class="border-bottom border--light-teal q-pb-xs q-mb-sm q-mt-md">
        {{ $t('Current customer?') }}

        <router-link
          to="/login"
          class="text-dark text-weight-bold"
          style="text-decoration: none;"
        >
          {{ $t('Sign in') }}
        </router-link>
      </div>

      <div class="hide-on-mobile" v-if="!this.simple">
        <small>
          <a
            :href="(appOptions || {}).status || 'https://statuspage.freshping.io/53709-Orderadmin'"
            target="_blank"
            class="text-dark"
          >
            {{ $t('System status page') }}
          </a>
        </small>
      </div>
    </form>
  </div>
</template>

<script>
import { Cookies } from 'quasar'

export default {
  name: 'Register',
  data () {
    return {
      email: null,
      password: null,
      repeatPassword: null,
      subscriber: null,
      domain: null,
      appOptions: window.appOptions,
      loadingNow: false,
      error: '',
      simple: false
    }
  },
  computed: {
    passwordError () {
      if (!this.password) {
        return ''
      }

      if (this.password.length < 9) {
        return 'Use at least 9 characters.'
      }

      if (!this.password.match(/[A-Z]/) || !this.password.match(/[a-z]/)) {
        return 'Use both low and upper case characters.'
      }

      if (!this.password.match(/\d+/)) {
        return 'Use one or more numbers.'
      }

      return ''
    },
    termsLink() {
      return this.appOptions.termsLink || '#';
    },
    cookiesLink() {
      return this.appOptions.cookiesLink || '#';
    },
    schema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'input',
                inputType: 'text',
                value: this.email,
                label: this.$t('Email'),
                color: 'light-teal',
                noFilled: this.simple ? false : true,
                wrapperStyleClasses: 'q-pb-sm',
                required: true,
                iconColor: 'light-grey-10',
                icon: this.simple ? '' : 'email',
                onChange: email => {
                  this.email = email
                }
              },
              {
                type: 'password',
                inputType: 'password',
                value: this.password,
                label: this.$t('Password'),
                wrapperStyleClasses: 'q-pb-sm',
                color: 'light-teal',
                noFilled: this.simple ? false : true,
                required: true,
                icon: this.simple ? '' : 'https',
                iconColor: 'light-grey-10',
                autoShow: 3000,
                hint: this.passwordError,
                details: [
                  this.$t('Use at least 9 characters.'),
                  this.$t('Use both low and upper case characters.'),
                  this.$t('Use one or more numbers.'),
                  this.$t('Optionally use special characters.')
                ],
                onInput: password => {
                  this.password = password
                }
              },
              {
                type: 'password',
                inputType: 'password',
                value: this.repeatPassword,
                label: this.$t('Confirm password'),
                wrapperStyleClasses: 'q-pb-sm',
                noFilled: this.simple ? false : true,
                icon: this.simple ? '' : 'https',
                required: true,
                color: 'light-teal',
                iconColor: 'light-grey-10',
                details: [],
                hint: this.repeatPassword && this.password && this.repeatPassword !== this.password
                  ? this.$t('Passwords did not match!')
                  : '',
                onInput: password => {
                  this.repeatPassword = password
                }
              },
              {
                type: 'multiselect',
                label: this.$t('Country'),
                wrapperStyleClasses: 'q-pb-sm',
                value: this.domain,
                noFilled: this.simple ? false : true,
                icon: this.simple ? '' : 'language',
                required: true,
                iconColor: 'light-grey-10',
                color: 'light-teal',
                if: !this.simple,
                customLabel: (row) => {
                  if (row && typeof row === 'object') {
                    return this.getName(row)
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' },
                      { type: 'eq', field: 'type', value: 'public' }
                    ]
                  }

                  return this.$service.domain.getAll(query)
                    .catch(error => {
                      this.error = error.message
                    })
                },
                onChange: domain => {
                  this.domain = domain
                }
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.subscriber = this.$eventBus.subscribe('rules', data => {
      this.appOptions = { ...data.appOptions }
    })

    this.simple = this.appOptions.versionType === 'simple'
  },
  unmounted () {
    this.subscriber.unsubscribe()
  },
  methods: {
    getName (row) {
      if (row.country) {
        return typeof row.country === 'object'
          ? row.country.name
          : row.country
      }

      return row.name
    },
    doLogin (user) {
      user.expiresTimestamp = user.expires_in
      localStorage.setItem('user', JSON.stringify(user))
      localStorage.setItem('appOptions', JSON.stringify(this.appOptions))

      const cookie = this.$q.cookies
        ? this.$q.cookies
        : Cookies

      cookie.set('server', this.appOptions.defaultServer)
      cookie.set('locale', this.$i18n.locale)
      cookie.set('user', user, user.expires_in)

      this.$userService.getById(0)
        .then(userdata => {
          localStorage.setItem('userData', JSON.stringify(userdata))

          if (this.logWithToken && userdata.allowedAdapters && userdata.allowedAdapters === 'TerminalAdapter') {
            localStorage.setItem('isLoginWithToken', true)
          }

          this.loadingNow = false
          const isEmployee = !!(userdata.roles || []).find(({ id }) => id === 7)
          this.$eventBus.update('rules', { user: userdata, app: isEmployee && window.innerWidth < 700 ? 'terminal' : 'app' })
        })
        .catch(error => {
          this.error = error.message
          this.loadingNow = false
        })
    },
    login () {
      this.loadingNow = true
      this.$userService.login(this.email, this.password)
        .then(user => {
          return this.doLogin(user)
        })
        .catch(error => {
          this.error = error.message
          this.loadingNow = false
        })
    },
    getDomain () {
      if (this.domain) {
        return this.domain.extId
      }

      return this.appOptions.domain
    },
    checkForm (e) {
      e.preventDefault()

      this.loadingNow = true
      const data = {
        email: this.email,
        username: this.email,
        password: this.password,
        domain: this.getDomain()
      }

      this.$userService.register(data)
        .then(() => {
          this.$eventBus.update('rules', { appOptions: { ...this.appOptions, domain: data.domain } })
          return this.login()
        })
        .catch(error => {
          this.loadingNow = false

          const jsonString = error.message
            .replace('Input errors: ', '')
            .replace(/array \(/g, '{')
            .replace(/\)/g, '}')
            .replace(/'([^']+)'/g, '"$1"')
            .replace(/=>/g, ':')
            .replace(/,\s*}/g, '}')
            .replace(/,\s*{/g, '{');

          this.error = JSON.parse(jsonString)
        })
    }
  }
}
</script>
